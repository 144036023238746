<template>
  <v-app
    id="login"
    class="primary"
  >
    <Notification />
    <v-container
      fill-height
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="5"
        >
          <v-card>
            <v-toolbar
              color="indigo darken-4"
              dark
              flat
              dense
            >
              <v-toolbar-title>
                Update password
              </v-toolbar-title>
            </v-toolbar>
            <ValidationObserver
              v-slot="{ passes }"
            >
              <v-card-text>
                Please write new password for {{ $route.params.email }}.
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="New Password"
                  rules="required|password:@confirm"
                >
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    :success="valid"
                    :type="show ? 'text' : 'password'"
                    label="Password*"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    required
                    @click:append="show = !show"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="confirm"
                  rules="required"
                >
                  <v-text-field
                    v-model="confirmation"
                    :error-messages="errors"
                    :success="valid"
                    :type="show ? 'text' : 'password'"
                    label="Repeat Password*"
                    append-icon="mdi-lock"
                    required
                  />
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="success"
                  :to="{ name: 'dashboard.login' }"
                >
                  Login
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  type="submit"
                  @click="passes(updatePassword)"
                >
                  Change password
                </v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Notification from '@/components/Notification'

export default {
  name: 'UpdatePassword',
  components: {
    Notification
  },
  data: () => ({
    confirmation: null,
    password: null,
    show: false
  }),
  methods: {
    updatePassword () {
      this.$http.put('/update_forgotten_password', {
        token: this.$route.params.token,
        password: this.password
      }).then(() => {
        this.$store.commit('setNotification', { color: 'success', message: 'Your password was successfully updated' })
        this.$router.push({ name: 'dashboard.login' })
      }).catch(error => {
        this.displayError(error)
      })
    }
  }
}
</script>

<style lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
