var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"primary",attrs:{"id":"login"}},[_c('Notification'),_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"5"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo darken-4","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" Update password ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',[_vm._v(" Please write new password for "+_vm._s(_vm.$route.params.email)+". "),_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":_vm.show ? 'text' : 'password',"label":"Password*","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"required":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":_vm.show ? 'text' : 'password',"label":"Repeat Password*","append-icon":"mdi-lock","required":""},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","to":{ name: 'dashboard.login' }}},[_vm._v(" Login ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":function($event){return passes(_vm.updatePassword)}}},[_vm._v(" Change password ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }